import { UploadListType } from 'antd/lib/upload/interface'
import { PaymentHistory } from 'containers/Order/shared/types'
import { atomWithReset } from 'jotai/utils'
import { TypeImage } from 'utils/constants'
export enum ListType {
  text = 'text',
  picture = 'picture',
  pictureCard = 'picture-card'
}

interface IFindBankAccountAtom {
  findBankNameShort: string
  findAccountNumber: string
}

interface IPaymentHistoryAtom extends PaymentHistory {}

export const clipboardClickedAtom = atomWithReset<boolean>(false)
export const totalUploadingLeftAtom = atomWithReset<number>(0)
export const totalToUploadAtom = atomWithReset<number>(0)

export const uploaderInfoAtom = atomWithReset<TypeImage[]>([TypeImage.NORMAL])

export const isDisabledAtom = atomWithReset<boolean>(false)
export const isBankTransferAtom = atomWithReset<boolean>(false)
export const turnOnCountingAtom = atomWithReset<boolean>(false)
export const turnOnLabelAtom = atomWithReset<boolean>(false)
export const uploadStyleAtom = atomWithReset<string>('regular')
export const folderUploadAtom = atomWithReset<string>('')
export const maximumUploadAtom = atomWithReset<number>(5)
// export const imagesAtom = atomWithReset<any[]>([])
export const findBankAccountAtom = atomWithReset<IFindBankAccountAtom>({} as IFindBankAccountAtom)
export const paymentHistoryAtom = atomWithReset<IPaymentHistoryAtom>({} as IPaymentHistoryAtom)
export const listTypeAtom = atomWithReset<UploadListType>(ListType.text as UploadListType)

export const widthAtom = atomWithReset<number>(0)
export const heightAtom = atomWithReset<number>(0)
export const fontSizeLabelAtom = atomWithReset<number>(13)
