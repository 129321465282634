import * as React from 'react'
import { Col, Grid, Modal } from 'antd'
import { CancelButton, ConfirmButton } from 'components/Button'
import { OldRow } from 'components/Row'
import { TextSm } from 'components/Typography'
import styled, { ThemeContext } from 'styled-components'
import { DarkTheme, LightTheme, lightTheme, PropsTheme } from 'theme'
import { STATUS_MODAL_ALERT } from 'utils/constants'

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons'

const { useBreakpoint } = Grid
const { useContext } = React
const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
    box-shadow: 0px 2px 8px ${(props: PropsTheme) => props.theme.shadowColor.veryLight};
  }

  .ant-modal-body {
    padding: 32px 32px;
  }
`
const IconStatus = ({ statusAlert }) => {
  const themeContext = useContext(ThemeContext) as LightTheme | DarkTheme

  switch (statusAlert) {
    case STATUS_MODAL_ALERT.SUCCESS:
      return <CheckCircleOutlined style={{ fontSize: '1.5rem', color: themeContext.colors.success }} />
    case STATUS_MODAL_ALERT.WARNING:
      return <ExclamationCircleOutlined style={{ fontSize: '1.5rem', color: themeContext.colors.warning }} />
    case STATUS_MODAL_ALERT.INFO:
      return <QuestionCircleOutlined style={{ fontSize: '1.5rem', color: themeContext.colors.warning }} />
    case STATUS_MODAL_ALERT.ERROR:
      return <CloseCircleOutlined style={{ fontSize: '1.5rem', color: themeContext.colors.danger }} />
    default:
      return <></>
  }
}
interface IAlertByStatus {
  statusAlert: STATUS_MODAL_ALERT
  setStatusAlert: React.Dispatch<React.SetStateAction<STATUS_MODAL_ALERT>>
  message: JSX.Element | string
  buttonMessage?: string
  callback?: any
}
export const AlertByStatus: React.FC<IAlertByStatus> = ({
  statusAlert,
  setStatusAlert,
  message,
  buttonMessage,
  callback
}) => {
  const handleOk = (e) => {
    setStatusAlert(null)
    if (callback) callback()
  }

  return (
    <ModalStyled
      open={[
        STATUS_MODAL_ALERT.SUCCESS,
        STATUS_MODAL_ALERT.WARNING,
        STATUS_MODAL_ALERT.INFO,
        STATUS_MODAL_ALERT.ERROR
      ].includes(statusAlert)}
      onOk={handleOk}
      closable={false}
      footer={null}
      centered
      className="!z-50"
    >
      <OldRow gutter={[16, 24]}>
        <Col xs={3} sm={2}>
          <IconStatus statusAlert={statusAlert} />
        </Col>
        <Col xs={21} sm={22}>
          <TextSm>{message}</TextSm>
        </Col>
      </OldRow>
      <OldRow justify="end">
        <Col>
          <ConfirmButton onClick={handleOk}>{buttonMessage || 'ตกลง'}</ConfirmButton>
        </Col>
      </OldRow>
    </ModalStyled>
  )
}

const DefaultFooter: React.FC<{
  loading: boolean
  handleCancel: () => void
  handleOk: () => void
  buttonMessage?: string
}> = ({ loading, handleCancel, handleOk, buttonMessage }) => {
  const screens = useBreakpoint()
  return (
    <OldRow gutter={[16, 0]} justify={screens?.xs ? 'center' : 'end'}>
      <Col>
        <CancelButton disabled={loading} onClick={handleCancel} loading={loading}>
          ยกเลิก
        </CancelButton>
      </Col>
      <Col>
        <ConfirmButton loading={loading} onClick={handleOk} disabled={loading}>
          {buttonMessage || 'ยืนยัน'}
        </ConfirmButton>
      </Col>
    </OldRow>
  )
}
interface IAlertByStatusWithInteract extends IAlertByStatus {
  handleOk: any
  onCancel?: () => void
  loading: boolean
  footer?: JSX.Element | JSX.Element[]
}

export const AlertByStatusWithInteract: React.FC<IAlertByStatusWithInteract> = ({
  handleOk,
  onCancel = () => true,
  statusAlert,
  setStatusAlert,
  message,
  loading,
  footer,
  buttonMessage
}) => {
  const handleCancel = () => {
    onCancel()
    setStatusAlert(null)
  }

  return (
    <ModalStyled
      open={[
        STATUS_MODAL_ALERT.SUCCESS,
        STATUS_MODAL_ALERT.WARNING,
        STATUS_MODAL_ALERT.INFO,
        STATUS_MODAL_ALERT.ERROR
      ].includes(statusAlert)}
      onOk={handleOk}
      onCancel={handleCancel}
      closable={false}
      footer={null}
      zIndex={2000}
    >
      <OldRow gutter={[0, 32]}>
        <Col xs={3} sm={2}>
          <IconStatus statusAlert={statusAlert} />
        </Col>
        <Col xs={21} sm={22}>
          <TextSm>{message}</TextSm>
        </Col>
      </OldRow>
      {footer || (
        <DefaultFooter
          loading={loading}
          handleCancel={handleCancel}
          handleOk={handleOk}
          buttonMessage={buttonMessage}
        />
      )}
    </ModalStyled>
  )
}

interface IAlertByStatusWithDescription extends IAlertByStatus {
  handleOk: any
  onCancel?: () => void
  loading: boolean
  footer?: JSX.Element | JSX.Element[]
  description: JSX.Element | string
}

export const AlertByStatusWithDescription: React.FC<IAlertByStatusWithDescription> = ({
  handleOk,
  onCancel = () => true,
  statusAlert,
  setStatusAlert,
  message,
  description,
  loading,
  footer
}) => {
  const handleCancel = () => {
    onCancel()
    setStatusAlert(null)
  }

  return (
    <ModalStyled
      open={[
        STATUS_MODAL_ALERT.SUCCESS,
        STATUS_MODAL_ALERT.WARNING,
        STATUS_MODAL_ALERT.INFO,
        STATUS_MODAL_ALERT.ERROR
      ].includes(statusAlert)}
      onOk={handleOk}
      onCancel={handleCancel}
      closable={false}
      footer={null}
      zIndex={2000}
    >
      <OldRow>
        <Col xs={3} sm={2}>
          <IconStatus statusAlert={statusAlert} />
        </Col>
        <Col xs={21} sm={22}>
          <TextSm>{message}</TextSm>
        </Col>
      </OldRow>
      <OldRow gutter={[16, 32]} style={{ paddingLeft: 40 }}>
        <Col>
          <TextSm color={lightTheme.fontColor.medium}>{description}</TextSm>
        </Col>
      </OldRow>
      {footer || <DefaultFooter loading={loading} handleCancel={handleCancel} handleOk={handleOk} />}
    </ModalStyled>
  )
}
