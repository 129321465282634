import React, { useEffect, useState } from 'react'
import { UploadListType } from 'antd/lib/upload/interface'
import { OldRow } from 'components/Row'
import { PaymentHistory } from 'containers/Order/shared/types'
import { GENERATE_UPLOAD_SIGNED_URL, GenerateUploadSignedUrl } from 'graphql-shared'
import { useSetAtom } from 'jotai'
import app from 'utils/auth/firebase'
import { TypeImage } from 'utils/constants'
import { FirebaseUtil } from 'utils/firebase'
import { uploadFileToGcsWithSignedUrl } from 'utils/upload'

import { useLazyQuery } from '@apollo/client'

import { Uploader } from './components/Uploader'
// import { Uploader } from './components/Uploader'
import {
  findBankAccountAtom,
  folderUploadAtom,
  fontSizeLabelAtom,
  heightAtom,
  // imagesAtom,
  isBankTransferAtom,
  isDisabledAtom,
  listTypeAtom,
  maximumUploadAtom,
  paymentHistoryAtom,
  totalUploadingLeftAtom,
  turnOnCountingAtom,
  turnOnLabelAtom,
  uploaderInfoAtom,
  uploadStyleAtom,
  widthAtom
} from './atoms'

export type Image = {
  src?: string
  imageType?: string
  order?: number
}

interface Props {
  listType: UploadListType
  folderUpload?: string
  images: Image[]
  uploaderInfo: TypeImage[]
  handleChange: (fileList: any) => void
  gutter?: [number, number]
  justify?: 'center' | 'start' | 'end' | 'space-around' | 'space-between'
  turnOnLabel?: boolean
  turnOnCounting?: boolean
  width?: number
  height?: number
  fontSizeLabel?: number
  maximumUpload?: number
  paymentHistory?: PaymentHistory
  findBankAccount?: any
  isBankTransfer?: boolean
  isDisabled?: boolean
  isPrivateImage?: boolean
  uploadStyle?: string
}

export const UploadImage: React.FC<Props> = ({
  listType,
  folderUpload: folderUploadParam,
  images: imgParam,
  handleChange,
  uploaderInfo: uploaderInfoParam,
  gutter = 40,
  justify = 'center',
  turnOnLabel = true,
  turnOnCounting = false,
  width: widthParam = 88,
  height: heightParam = 88,
  fontSizeLabel,
  maximumUpload = 5,
  paymentHistory = {},
  findBankAccount,
  isBankTransfer: isBankTransferParam = false,
  isDisabled: isDisabledParam = false,
  isPrivateImage = false,
  uploadStyle = 'regular'
}) => {
  /* -------------------------------------------------------------------------- */
  /*                                  useState                                  */
  /* -------------------------------------------------------------------------- */
  const [fileList, setFileList] = useState([])
  const [queueUpload, setQueueUpload] = useState([])
  // const [queueUpload, setQueueUpload] = useAtom(queueUploadAtom)

  const setUploaderInfo = useSetAtom(uploaderInfoAtom)
  const setIsDisabled = useSetAtom(isDisabledAtom)
  const setUploadingLeft = useSetAtom(totalUploadingLeftAtom)
  const setIsBankTransfer = useSetAtom(isBankTransferAtom)
  const setTurnOnLabel = useSetAtom(turnOnLabelAtom)
  // const setImages = useSetAtom(imagesAtom)
  const setFindBankAccount = useSetAtom(findBankAccountAtom)
  const setPaymentHistory = useSetAtom(paymentHistoryAtom)
  const setListType = useSetAtom(listTypeAtom)
  const setWidth = useSetAtom(widthAtom)
  const setHeight = useSetAtom(heightAtom)
  const setUploadStyle = useSetAtom(uploadStyleAtom)
  const setFolderUpload = useSetAtom(folderUploadAtom)
  const setTurnOnCounting = useSetAtom(turnOnCountingAtom)
  const setFontSizeLabel = useSetAtom(fontSizeLabelAtom)
  const setMaximumUpload = useSetAtom(maximumUploadAtom)

  const firebaseUtil = new FirebaseUtil(app, { bucketName: process.env.NEXT_PUBLIC_PRIVATE_BUCKET_NAME })

  /* -------------------------------------------------------------------------- */
  /*                                   GraphQL                                  */
  /* -------------------------------------------------------------------------- */
  const [generateUploadSignedUrl, { data, loading: uploading }] = useLazyQuery<GenerateUploadSignedUrl>(
    GENERATE_UPLOAD_SIGNED_URL,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        // if (uploadingLeft - 1 === 0) {
        /* ------------------------------- reset total ------------------------------ */
        //   setTotalToUpload(0)
        // }

        setUploadingLeft((prev) => prev - 1)
        const generatedURL = data?.generateUploadSignedUrl?.url

        // Split the URL by "?" to remove the query parameters
        const parts = generatedURL?.split('?')
        const path = parts[0]

        // Get the part after the last slash
        const partsAfterSlash = path.split('/').filter((part) => part.length > 0)
        const fileName = partsAfterSlash[partsAfterSlash.length - 1]

        for (const [index, thisFile] of queueUpload.entries()) {
          const { filename, type } = thisFile

          const findThisInfoUpload = generatedURL?.includes(filename)

          if (findThisInfoUpload) {
            setImagePreview(thisFile)
          } else {
          }
        }
      }
    }
  )

  /* -------------------------------------------------------------------------- */
  /*                                  Functions                                 */
  /* -------------------------------------------------------------------------- */
  const setImagePreview = async (infoUpload) => {
    const { file, filename, imageType, type, targetUpload } = infoUpload

    try {
      if (isPrivateImage) {
        const {
          metadata: { fullPath }
        } = await firebaseUtil.uploadPrivateObject(file, filename)
        const url = await firebaseUtil.getPrivateObject(fullPath)
        const fileListFiltered = fileList?.filter((item) => item?.order !== targetUpload)
        const fileListUpdated = [
          ...fileListFiltered,
          {
            src: url,
            imageType,
            order: targetUpload
          }
        ]

        //TODO: DRY this up
        handleChange(fileListUpdated)
        setFileList(fileListUpdated)
      } else {
        const responseStatusCode = await uploadFileToGcsWithSignedUrl(data.generateUploadSignedUrl.url, file, type)
        if (responseStatusCode === 200) {
          const src = `https://storage.googleapis.com/market-futuremakers/${filename}`
          const fileListFiltered = fileList?.filter((item) => item?.order !== targetUpload)
          const fileListUpdated = [
            ...fileListFiltered,
            {
              src,
              imageType,
              order: targetUpload
            }
          ]
          handleChange(fileListUpdated)
          setFileList(() => fileListUpdated)
        }
      }

      setQueueUpload((prev) => prev?.slice(1) || []) // remove the first element because it's already uploaded
    } catch (error) {}
  }
  /* -------------------------------------------------------------------------- */
  /*                                  useEffect                                 */
  /* -------------------------------------------------------------------------- */

  // set all params from parent
  useEffect(() => {
    let isSubscribed = true

    if (isSubscribed) {
      setIsBankTransfer(isBankTransferParam)
      setTurnOnLabel(turnOnLabel)
      setWidth(widthParam)
      setHeight(heightParam)
      // setImages(imgParam)
      setFindBankAccount(findBankAccount)
      setPaymentHistory(paymentHistory)
      setListType(listType)
      setUploadStyle(uploadStyle)
      setFolderUpload(folderUploadParam)
      setTurnOnCounting(turnOnCounting)
      setFontSizeLabel(fontSizeLabel)
      setMaximumUpload(maximumUpload)
    }
    return () => {
      isSubscribed = false
    }
  }, [])

  useEffect(() => {
    setIsDisabled(isDisabledParam)
  }, [isDisabledParam])

  useEffect(() => {
    setUploaderInfo(uploaderInfoParam)
  }, [uploaderInfoParam])

  useEffect(() => {
    const uploadSignUrl = async () => {
      const thisFile = queueUpload[0]
      const { filename, type } = thisFile
      generateUploadSignedUrl({ variables: { filename: filename, type } })
      await new Promise((resolve) => {
        setTimeout(resolve, 2000)
      })
    }
    if (queueUpload?.length > 0) {
      uploadSignUrl()
    }
  }, [queueUpload])

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      setFileList(() => imgParam)
    }
    return () => {
      isSubscribed = false
    }
  }, [imgParam])

  return (
    <div id="custom-button-upload">
      <OldRow gutter={gutter} justify={justify}>
        <Uploader
          handleChange={handleChange}
          uploading={uploading}
          images={imgParam}
          fileList={fileList}
          setFileList={setFileList}
          setQueueUpload={setQueueUpload}
        />
      </OldRow>
    </div>
  )
}
