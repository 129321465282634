import { Menu } from 'antd'
import styled from 'styled-components'
import { PropsTheme } from 'theme'

export const DefaultDropdownMenu = styled(Menu)`
  &.ant-dropdown-menu {
    .ant-dropdown-menu-item {
      min-width: 120px;
      /* position: relative; */
      font-size: ${(props: PropsTheme) => props.theme.fontSizes.sm};
      height: 36px;
      display: flex;
      align-items: center;
    }
    border-radius: 8px;
    border: ${(props: PropsTheme) => `1px solid ${props.theme.colors.secondary}`};
    padding: 8px 16px;
  }

  && .ant-dropdown-menu-item-divider {
    margin: 8px 0 !important;
  }
`

export const StyledMenu = styled(Menu)`
  border-radius: 8px;
  border: ${(props: PropsTheme) => `1px solid ${props.theme.colors.secondary}`};
  padding: 8px 0px;

  &&.ant-menu-item {
    display: flex;
    align-items: center;
    margin: 8px 16px !important;
  }
  li.ant-menu-item {
    height: 36px !important;
    line-height: 36px;
    margin: 0px;
  }
  .ant-menu-title-content {
    font-size: 13px !important;
  }
  .ant-menu-item-divider {
    margin: 8px 16px !important;
  }
`
