import React from 'react'
import { Row, RowProps } from 'antd'

export const OldRow: React.FC<RowProps> = ({ children, gutter, style, ...rest }) => {
  const getSpaceBetweenRow = () => {
    return gutter && typeof gutter === 'object' && gutter[1]
  }

  return (
    <Row style={{ ...style, marginBottom: getSpaceBetweenRow() }} gutter={gutter} {...rest}>
      {children}
    </Row>
  )
}
