import fetch from 'isomorphic-unfetch'
import { v4 as uuidv4 } from 'uuid'

export const getBase64 = (file: File | Blob): Promise<string | ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export const generateUniqueFilename = (filenameExt: string) => {
  const filename = uuidv4() + '.' + filenameExt
  return filename
}

export const splitFilenameWithoutExt = (filename: string) => {
  // Split the filename without the extension and limit it to 20 characters
  const splitedFilename = filename
    .replace(/\.[^/.]+$/, '') // Remove the file extension
    .substring(0, 20) // Limit the filename to 20 characters
    .replace(/\s/g, '_') // Replace any spaces with underscores
  return splitedFilename
}

export const uploadFileToGcsWithSignedUrl = async (signedUrl: string, file: File | Blob, type: string) => {
  try {
    const response = await fetch(signedUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': type
      },
      body: file
    })

    return response.status
  } catch (error) {
    console.log('Error at uploadFileToGcsWithSignedUrl', error)
  }
}
