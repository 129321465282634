import { FirebaseApp, initializeApp } from '@firebase/app'

let firebaseApp: FirebaseApp

if (!firebaseApp) {
  firebaseApp = initializeApp({
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID
  })
}

export default firebaseApp
