import firebase from '@firebase/app'
import { addDoc, collection, Firestore, getDocs, getFirestore } from '@firebase/firestore'
import { deleteObject, FirebaseStorage, getDownloadURL, getStorage, ref, uploadBytes } from '@firebase/storage'

interface IOptions {
  bucketName: string
}

export class FirebaseUtil {
  private readonly app: firebase.FirebaseApp
  private readonly db: Firestore
  private readonly storage: FirebaseStorage

  constructor(app: firebase.FirebaseApp, options?: IOptions) {
    this.app = app
    this.db = getFirestore(app)

    if (options) {
      const { bucketName } = options
      this.storage = getStorage(app, bucketName)
    }
  }

  async addDocToFirestore<Data>(collectionName: string, data: Data) {
    const docRef = await addDoc(collection(this.db, collectionName), data)
    return docRef
  }

  async getDocFromFirestore<Data>(collectionName: string) {
    const querySnapshot = await (await getDocs(collection(this.db, collectionName))).docs
    let data = [] as Data[]

    for (const doc of querySnapshot) {
      data.push(doc.data() as any)
    }

    return data
  }

  async getPrivateObject(url: string) {
    const fileName = this.formatUrl(url)

    try {
      const downloadUrl = await this.getDownloadUrl(fileName)

      return downloadUrl
    } catch (error) {
      return url
    }
  }

  async uploadPrivateObject(file: File | Blob, fileName: string) {
    try {
      const response = await this.uploadFileToBucket(file, fileName)
      return response
    } catch (error) {
      console.log(error)
      throw new Error('Error: failed to upload file')
    }
  }

  public getFileName(fileName: string) {
    try {
      const storageRef = ref(this.storage, fileName)
      return storageRef.name
    } catch (error) {
      console.error(error)
      throw new Error('Error: failed to get file name.')
    }
  }

  public async deleteFiles(fileUrls: string[]) {
    try {
      for (let index = 0; index < fileUrls.length; index++) {
        const storageRef = ref(this.storage, fileUrls[index])
        await deleteObject(storageRef)
      }
    } catch (error) {
      console.error(error)
      throw new Error('Error: failed to get file name.')
    }
  }

  private formatUrl(fileName: string): string {
    //!will be delete when fully migrate to new bucket.
    const oldBucketPattern = 'https://storage.googleapis.com/market-futuremakers/'
    const newBucketPattern = ''
    return fileName.replace(oldBucketPattern, newBucketPattern)
  }

  private async getDownloadUrl(fileName: string): Promise<string> {
    const storageRef = ref(this.storage, fileName)
    const url = await getDownloadURL(storageRef)
    return url
  }

  private async uploadFileToBucket(file: File | Blob, fileName: string) {
    const storageRef = ref(this.storage, fileName)
    const response = uploadBytes(storageRef, file)
    return response
  }
}
