import { Dispatch, SetStateAction, useContext, useMemo, useRef, useState } from 'react'
import Image from 'next/image'
import { Col, Progress, Upload, UploadFile } from 'antd'
import { UploadChangeParam } from 'antd/lib/upload'
import Dragger from 'antd/lib/upload/Dragger'
import { AlertByStatusWithInteract } from 'components/Alert'
import { ButtonLink } from 'components/Button'
import { OldRow } from 'components/Row'
import { HeaderLg, TextSm, TextSmMinus } from 'components/Typography'
import {
  findBankAccountAtom,
  folderUploadAtom,
  fontSizeLabelAtom,
  heightAtom,
  isBankTransferAtom,
  isDisabledAtom,
  ListType,
  listTypeAtom,
  maximumUploadAtom,
  paymentHistoryAtom,
  totalToUploadAtom,
  totalUploadingLeftAtom,
  turnOnCountingAtom,
  turnOnLabelAtom,
  uploaderInfoAtom,
  uploadStyleAtom,
  widthAtom
} from 'components/UploadImage/atoms'
import { useAtom, useAtomValue } from 'jotai'
import { uniqueId } from 'lodash'
import path from 'path'
import styled, { css, ThemeContext } from 'styled-components'
import { DarkTheme, LightTheme } from 'theme'
import { STATUS_MODAL_ALERT, TypeImage } from 'utils/constants'
import { generateUniqueFilename, splitFilenameWithoutExt } from 'utils/upload'

import { DeleteOutlined, LoadingOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons'

const WrapperActionButton = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  position: absolute;
  bottom: 6px;
  background: rgba(42, 98, 254, 0.6);
  justify-items: center;
  line-height: 0.25;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  svg {
    font-size: 1.2rem !important;
    color: white !important;
    align-items: center !important;
  }
`

const LabelImageInfo = styled(TextSm)`
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  margin: 0px auto 10px auto;
`

const WrapperUpdateImage = styled.div`
  display: grid;
  position: relative;

  img {
    border-radius: 8px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
    object-fit: cover;
  }
`

interface IUpload {
  width?: number
  height?: number
}

const UploadStyled = styled(Upload).attrs((props: IUpload) => {
  return {
    width: props?.width || '88',
    height: props?.height || '88'
  }
})`
  .ant-upload.ant-upload-select-picture-card {
    width: ${(props) => `${props.width}px`};
    height: ${(props) => `${props.height}px`};
  }

  .upload-select {
    width: 120px;
    height: 90px;
    border: ${({ isClicked }) => (!isClicked ? '1px dashed #d9d9d9' : '1px dashed #2afe97')};
    border-radius: 0.4rem;
    padding: 20px;
    cursor: pointer;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.02);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

const ClipboardStyled = styled.div<{ isClicked: boolean; disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // margin-bottom: 5px;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.6;
    `}
  & .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  & .paste-container {
    width: 120px;
    height: 90px;
    border: ${({ isClicked }) => (!isClicked ? '1px dashed #d9d9d9' : '1px dashed #2afe7b')};
    border-radius: 0.4rem;
    padding: 20px;
    cursor: pointer;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.02);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  & .pasted-image {
    max-width: 100%;
    margin-top: 20px;
  }
`

interface UploaderProps {
  handleChange: ([...args]?: any) => void
  uploading?: boolean
  hideFileName?: boolean
  images: any[]
  fileList: any[]
  setFileList: Dispatch<SetStateAction<any[]>>
  setQueueUpload: Dispatch<SetStateAction<any[]>>
}

export const Uploader: React.FC<UploaderProps> = ({
  handleChange,
  uploading = false,
  hideFileName = true,
  images,
  fileList,
  setFileList,
  setQueueUpload
}) => {
  const localUploading = useMemo(() => uploading, [uploading])
  const [statusAlert, setStatusAlert] = useState(null)
  const [targetDelete, setTargetDelete] = useState(null)

  const clipboardRef = useRef(null)
  const uploadImgRef = useRef(null)

  const theme = useContext(ThemeContext) as LightTheme | DarkTheme

  // ==========
  // =  Atom  =
  // ==========
  // const setQueueUpload = useSetAtom(queueUploadAtom)
  const [totalToUpload, setTotalToUpload] = useAtom(totalToUploadAtom)
  const [uploadingLeft, setUploadingLeft] = useAtom(totalUploadingLeftAtom)
  const localUploaderInfo = useAtomValue(uploaderInfoAtom)

  const maximumUpload = useAtomValue(maximumUploadAtom)
  const folderUpload = useAtomValue(folderUploadAtom)
  const uploadStyle = useAtomValue(uploadStyleAtom)
  const isDisabled = useAtomValue(isDisabledAtom)
  const isBankTransfer = useAtomValue(isBankTransferAtom)
  const turnOnLabel = useAtomValue(turnOnLabelAtom)
  // const images = useAtomValue(imagesAtom)
  const findBankAccount = useAtomValue(findBankAccountAtom)
  const paymentHistory = useAtomValue(paymentHistoryAtom)
  const listType = useAtomValue(listTypeAtom)
  const width = useAtomValue(widthAtom)
  const height = useAtomValue(heightAtom)

  const progress = useMemo(() => {
    return ((totalToUpload - uploadingLeft) / totalToUpload) * 100
  }, [totalToUpload, uploadingLeft])

  const { findBankNameShort, findAccountNumber } = useMemo(() => {
    return {
      findBankNameShort: findBankAccount?.findBankNameShort || null,
      findAccountNumber: findBankAccount?.findAccountNumber || null
    }
  }, [findBankAccount])

  const { paidAmountFinal } = useMemo(() => {
    return {
      paidAmountFinal: paymentHistory?.paidAmount || null
    }
  }, [paymentHistory])

  const [clipboardClicked, setClipboardClicked] = useState(false)
  const uploadEl = useRef(null)
  let indexCoverImage = 0
  let indexNormalImage = 0

  // ============================================
  // =                  Function                =
  // ============================================
  //*This is a preparation for uploading with signed url
  const handleCustomRequest = (
    info: UploadChangeParam | { file?: UploadFile<any>; fileList?: File[] },
    targetUpload: number,
    type: 'antd' | 'htmlElem' = 'antd'
  ) => {
    if (!info) return null

    if (type === 'htmlElem') {
      const { fileList } = info as { file?: UploadFile<any>; fileList?: File[] }
      // for fileList
      if (fileList?.length > 0) {
        const canUploadMoreLeft = maximumUpload - images?.length
        const limitedFileList = fileList?.slice(0, canUploadMoreLeft)
        if (limitedFileList?.length == 0) return null

        setUploadingLeft(limitedFileList?.length)
        setTotalToUpload(limitedFileList?.length)

        Array.from(limitedFileList?.entries()).forEach(async ([index, thisFile]) => {
          if (!thisFile) return null
          const { name, type } = thisFile
          const originFileObj = await thisFile.arrayBuffer()
          const filenameExt = name.split(/\.(?=[^\.]+$)/)[1]
          const filename = isBankTransfer
            ? `${splitFilenameWithoutExt(name)}-${String(findBankNameShort).trim()}-${String(
                findAccountNumber
              ).trim()}-${paidAmountFinal * Math.pow(10, 4)}-${Date.now()}.${filenameExt}`
            : generateUniqueFilename(filenameExt)
          const folderName = folderUpload || 'product-images'
          const fullpath = path.join(folderName, filename)

          setQueueUpload((prev) => [
            ...prev,
            {
              file: originFileObj,
              filename: fullpath,
              imageType: localUploaderInfo[targetUpload],
              targetUpload: targetUpload + index,
              type
            }
          ])
        })
      }
    } else if (type === 'antd') {
      const { file, fileList } = info as UploadChangeParam
      // clear input value, enable to select same file twice
      //   if (uploadImgRef?.current?.value) {
      //     uploadImgRef.current.value = null
      //   }

      // for fileList
      if (fileList?.length > 0) {
        const canUploadMoreLeft = maximumUpload - images?.length
        const limitedFileList = fileList?.slice(0, canUploadMoreLeft)
        if (limitedFileList?.length == 0) return null

        setUploadingLeft(limitedFileList?.length)
        setTotalToUpload(limitedFileList?.length)

        const newQueues = Array.from(limitedFileList?.entries()).map(([index, thisFile]) => {
          if (!thisFile) return null
          const { originFileObj, name, type } = thisFile
          // const originFileObj = await thisFile.arrayBuffer()
          const filenameExt = name.split(/\.(?=[^\.]+$)/)[1]
          const filename = isBankTransfer
            ? `${splitFilenameWithoutExt(name)}-${String(findBankNameShort).trim()}-${String(
                findAccountNumber
              ).trim()}-${paidAmountFinal * Math.pow(10, 4)}-${Date.now()}.${filenameExt}`
            : generateUniqueFilename(filenameExt)
          const folderName = folderUpload || 'product-images'
          const fullpath = path.join(folderName, filename)
          return {
            file: originFileObj,
            filename: fullpath,
            imageType: localUploaderInfo[targetUpload],
            targetUpload: targetUpload + index,
            type
          }
        })

        setQueueUpload(() => [...newQueues])
      } else if (file) {
        const { originFileObj, name, type } = info.file

        const filenameExt = name.split(/\.(?=[^\.]+$)/)[1]
        const filename = isBankTransfer
          ? `${splitFilenameWithoutExt(name)}-${String(findBankNameShort).trim()}-${String(findAccountNumber).trim()}-${
              paidAmountFinal * Math.pow(10, 4)
            }-${Date.now()}.${filenameExt}`
          : generateUniqueFilename(filenameExt)
        const folderName = folderUpload || 'product-images'
        const fullpath = path.join(folderName, filename)

        setQueueUpload((prev) => [
          ...prev,
          {
            file: originFileObj,
            filename: fullpath,
            imageType: localUploaderInfo[targetUpload],
            targetUpload: targetUpload + (prev?.length + 1 || 0),
            type
          }
        ])
      }
    }
  }

  const onClickDeleteImage = (targetDelete) => {
    setStatusAlert(STATUS_MODAL_ALERT.WARNING)
    setTargetDelete(targetDelete)
  }

  const handleDeleteImage = (image) => {
    const fileListFiltered = fileList.filter((item) => item?.order !== image?.order)
    setFileList(fileListFiltered)
    handleChange(fileListFiltered)
    setStatusAlert(null)
    setClipboardClicked(false)
  }

  const handleImagePaste = (event) => {
    const clipboardData = event.clipboardData
    const items = clipboardData.items
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1 && clipboardClicked) {
        const imageFile = items[i].getAsFile()
        const fileData = {
          file: {
            uid: uniqueId(),
            name: imageFile.name,
            originFileObj: imageFile,
            type: imageFile.type
          },
          fileList: null
        }
        handleCustomRequest(fileData, i)
      }
    }
  }

  const handleToggleClick = () => {
    setClipboardClicked(!clipboardClicked)
  }

  const countImageByType = (typeImage: TypeImage) => localUploaderInfo.filter((info) => info === typeImage).length

  const getLabelInfoWithNumber = (typeImage: TypeImage, indexCoverImage: number, indexNormalImage: number) => {
    if (typeImage === TypeImage.COVER)
      if (countImageByType(typeImage) > 1) {
        return 'ภาพปก' + indexCoverImage
      } else return 'ภาพปก'
    else if (typeImage === TypeImage.NORMAL)
      if (countImageByType(typeImage) > 1) {
        return 'รูปภาพ ' + indexNormalImage
      } else return 'รูปภาพ '
  }

  return (
    <>
      <AlertByStatusWithInteract
        handleOk={() => handleDeleteImage(targetDelete)}
        statusAlert={statusAlert}
        setStatusAlert={setStatusAlert}
        message="ลบรูปภาพ"
        loading={false}
      />
      {
        localUploaderInfo.map((typeImage, index) => {
          if (typeImage === TypeImage.COVER) indexCoverImage++
          else if (typeImage === TypeImage.NORMAL) indexNormalImage++
          const isTarget = fileList?.find((item) => item.order === index && item.src)

          return (
            <Col span={24} key={index}>
              {isTarget ? (
                <>
                  {localUploading && !isTarget && <TextSmMinus>กำลังอัพโหลด รอสักครู่...</TextSmMinus>}
                  {uploadStyle === 'regular' && (
                    <WrapperUpdateImage>
                      {listType === ListType.text ? (
                        <div style={{ position: 'relative' }}>
                          <Image src={isTarget.src} alt={`img-preview-${index}`} width={width} height={height} />
                        </div>
                      ) : (
                        <div style={{ position: 'absolute' }}>
                          <Image src={isTarget.src} alt={`img-preview-${index}`} width={width} height={height} />
                        </div>
                      )}
                      <UploadStyled
                        width={width}
                        height={height}
                        ref={uploadEl}
                        data-testid={`uploader-${index + 1}`}
                        listType={listType}
                        showUploadList={false}
                        onChange={(info) => {
                          handleCustomRequest(info, index)
                        }}
                        // preview
                        // placeholder={'blur'}
                      />
                      <WrapperActionButton
                        className={
                          'hover:cursor-pointer hover:bg-opacity-100 hover:bg-indigo-400 transition-all duration-300 ease-out'
                        }
                        style={{ width: width }}
                        onClick={() => onClickDeleteImage(isTarget)}
                      >
                        <ButtonLink type="link" icon={<DeleteOutlined />} />
                      </WrapperActionButton>
                      {/* {!hideFileName && (
                        <TextSmMinus
                          className={'hover:cursor-pointer text-nowrap'}
                          style={{
                            maxWidth: width,
                            overflow: ellipsis ? 'visible' : 'hidden',
                            textOverflow: 'ellipsis'
                          }}
                          onClick={() => setEllipsis((prev) => !prev)}
                        >
                          <LinkOutlined className="mr-2" />
                          {decodeURIComponent(isTarget?.src)?.split('/').pop()?.split('?')?.shift()}
                        </TextSmMinus>
                      )} */}
                    </WrapperUpdateImage>
                  )}
                </>
              ) : (
                <OldRow gutter={[10, 0]}>
                  {uploadStyle === 'regular' && (
                    <>
                      <Col span={12}>
                        <ClipboardStyled
                          tabIndex={0}
                          isClicked={clipboardClicked}
                          onFocus={handleToggleClick}
                          onBlur={handleToggleClick}
                          disabled={isDisabled}
                          onDrop={(e) => {
                            e.preventDefault()
                            handleCustomRequest({ fileList: Array.from(e?.dataTransfer?.files || []) }, index)
                          }}
                        >
                          <div className="paste-container" ref={clipboardRef} onPaste={handleImagePaste}>
                            <ButtonLink
                              disabled={isDisabled}
                              type="link"
                              color={clipboardClicked ? theme.colors.success : theme.colors.main}
                            >
                              <div className={'flex flex-col justify-center items-center'}>
                                <PlusOutlined style={{ fontSize: 12 }} className={' mx-auto'} />
                                <span className="upload-select">
                                  {!clipboardClicked ? 'โหมดวางรูปจาก Clipboard' : 'กด ctrl+v เพื่อวางภาพ'}
                                </span>
                              </div>
                            </ButtonLink>
                          </div>
                        </ClipboardStyled>
                      </Col>
                      <input
                        ref={uploadImgRef}
                        name={'uploadImage'}
                        type={'file'}
                        className={'hidden'}
                        // accept={`${AcceptMIMEType.PNG}, ${AcceptMIMEType.JPEG}`}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e?.target?.files?.length > 0) {
                            handleCustomRequest(
                              {
                                fileList: Array?.from(e?.target?.files ? e?.target?.files : [])
                              },
                              index,
                              'htmlElem'
                            )
                          }
                        }}
                      />
                      <Col
                        span={12}
                        // onClick={() => {
                        //   uploadImgRef?.current?.click()
                        // }}
                      >
                        {isTarget ? (
                          <>
                            <Image
                              loading="lazy"
                              src={isTarget.src}
                              alt={isTarget?.src?.split('/').pop()}
                              width={width}
                              height={height}
                              unoptimized={true}
                            />
                          </>
                        ) : (
                          <UploadStyled
                            isClicked={false}
                            // className={'!pointer-events-none'}
                            onChange={(info) => {
                              handleCustomRequest(info, index)
                            }}
                            // disabled={true}
                          >
                            {listType === ListType.text ? (
                              <ButtonLink disabled={isDisabled} type="link">
                                <span className="upload-select">
                                  เลือกรูปภาพจากในเครื่อง
                                  {localUploading && <LoadingOutlined spin className="inline" />}
                                </span>
                              </ButtonLink>
                            ) : (
                              <UploadButton fileList={fileList} />
                            )}
                          </UploadStyled>
                        )}
                      </Col>
                    </>
                  )}

                  {uploadStyle === 'dragAndDrop' && (
                    <>
                      <Col span={24} style={{ marginBottom: 16 }}>
                        <Dragger
                          disabled={isDisabled}
                          {...{
                            // style: {width: uploadPanelWidth},
                            name: 'file',
                            action: '/api/noop',
                            // accept: '.csv',
                            multiple: true,
                            showUploadList: true,
                            onDrop(e) {
                              e?.dataTransfer?.files?.length > 0 &&
                                handleCustomRequest({ fileList: Array.from(e?.dataTransfer?.files || []) }, index)
                            },
                            loading: 'lazy'
                          }}
                          onChange={(info) => {
                            handleCustomRequest(info, index)
                          }}
                        >
                          <p className="ant-upload-drag-icon">
                            {(localUploading && <LoadingOutlined spin style={{ fontSize: 24 }} />) || (
                              <PlusCircleOutlined style={{ fontSize: 24 }} />
                            )}
                          </p>

                          {`${fileList?.length}/${maximumUpload}`}

                          <HeaderLg>คลิ๊กหรือลากไฟล์เข้าพื้นที่นี้เพื่ออัพโหลด</HeaderLg>
                          <p className="ant-upload-text">สามารถอัพโหลดได้ทีละไฟล์หรือหลายไฟล์พร้อมกันได้</p>
                          {/* <p className="ant-upload-text">โดยจำกัดขนาดไฟล์ละไม่เกิน 5 Mb</p> */}
                        </Dragger>

                        {totalToUpload > 0 && fileList?.length > 0 && (
                          <OldRow>
                            {uploadingLeft > 0 && `เหลืออัพโหลดอีก ${uploadingLeft} ไฟล์`}
                            <Progress percent={progress} showInfo={false} />
                          </OldRow>
                        )}
                      </Col>

                      <></>
                    </>
                  )}
                </OldRow>
              )}
              {turnOnLabel && (
                <LabelImageInfo data-testid={`label-info-${index + 1}`}>
                  {getLabelInfoWithNumber(typeImage, indexCoverImage, indexNormalImage)}
                </LabelImageInfo>
              )}
            </Col>
          )
        }) as any
      }
    </>
  )
}

const UploadButton = ({ fileList }) => {
  const themeContext = useContext(ThemeContext) as LightTheme | DarkTheme

  const maximumUpload = useAtomValue(maximumUploadAtom)
  const turnOnCounting = useAtomValue(turnOnCountingAtom)
  const fontSizeLabel = useAtomValue(fontSizeLabelAtom)

  return (
    <div style={{ fontSize: fontSizeLabel ? `${fontSizeLabel}px` : '' }}>
      <PlusCircleOutlined />
      {turnOnCounting && (
        <TextSm
          style={{ fontSize: fontSizeLabel ? `${fontSizeLabel}px` : '' }}
          color={themeContext.fontColor.light}
        >{`${fileList?.length + 1}/${maximumUpload}`}</TextSm>
      )}
    </div>
  )
}
